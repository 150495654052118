import store from '@store'

import { gbtEncrypt } from '@utils/encrypt'
import { getGbtToken } from '@utils/auth'

import {
    GBT_SET_ACCOUNT_URL as setAccountUrl,
    GBT_ENTERPRISE_AUTH_OR_INFO_URL as enterpriseAuthUrl,
    GBT_FORGET_PASSWORD_URL as forgetPsdUrl,
    GBT_EDIT_PASSWORD_URL as editPsdUrl,
    GBT_ACCOUNT_INFO as accountInfoUrl
} from '@config/gbtSenceUrlMap'


function fn(params) {
    return Object.keys(params)
        .reduce((pre, cur) => pre.concat(`${cur}=${params[cur]}`), [])
        .join('&')
}

export default function openNewPage(path, params = {}, target = '_blank') {
    window.open(`${path}?${fn(params)}`, target)
}


function getParams(params = {}) {
    const authProperties = store.getters.authProperties || {}
    const callbackUrl = encodeURIComponent(window.location.href)

    const token = params.ac || getGbtToken() || ''
    const ac = gbtEncrypt(token)

    return {
        callbackUrl,
        loginUrl: callbackUrl,
        platformCode: authProperties.platformCode,
        ac,
        ...params
    }
}

/** 设置账号/密码 */
export function goSetPassword(params) {
    openNewPage(setAccountUrl, getParams(params))
}

/** 企业认证 */
export function goEnterpriseAuth(params) {
    openNewPage(enterpriseAuthUrl, getParams(params))
}

/** 忘记密码 */
export function goForgetPassword(params = {}) {
    params.ac = ''
    openNewPage(forgetPsdUrl, getParams(params))
}

/** 修改密码 */
export function goEditPassword(params) {
    openNewPage(editPsdUrl, getParams(params))
}

/** 账号信息 */
export function goAccountInfo(params) {
    openNewPage(accountInfoUrl, getParams(params))
}
