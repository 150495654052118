import { KJUR, KEYUTIL } from 'jsrsasign'
import { GONGBAOTONG_PRIVATE_KEY, GONGBAOTONG_PUBLIC_KEY } from '@/config'

/**
 * RSA加密
 * @param str
 * @returns {*}
 */
export function gbtEncrypt(str) {
    let pub = KEYUTIL.getKey(GONGBAOTONG_PUBLIC_KEY)
    return KJUR.crypto.Cipher.encrypt(str, pub)
}

/**
 * RSA解密
 * @param enc
 * @returns {*}
 */
export function gbtDecrypt(enc) {
    let pri = KEYUTIL.getKey(GONGBAOTONG_PRIVATE_KEY)
    return KJUR.crypto.Cipher.decrypt(enc, pri)
}
