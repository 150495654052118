const baseUrl = process.env.VUE_APP_GBT_BASE_URL

const GBT_LOGIN_URL = baseUrl + '/gbt-login/index.html' // 登录
const GBT_SET_ACCOUNT_URL = baseUrl + '/gbt-login/account.html' // 设置账号/密码
const GBT_FORGET_PASSWORD_URL = baseUrl + '/gbt-login/forget-password.html' // 忘记密码
const GBT_EDIT_PASSWORD_URL = baseUrl + '/account/changePassword' // 修改密码
const GBT_ENTERPRISE_AUTH_OR_INFO_URL = baseUrl + '/enperprise/auth/index' // 企业认证/企业信息 （需要传递platformCode/callbackUrl(业务url))
const GBT_QUALIFICAT_AUTH_URL = baseUrl + '/qualificatAuth/myForm' // 企业资质认证 （需要传递platformCode/callbackUrl(业务url))
const GBT_CERTIFICATION_CENTER = baseUrl + '/certificationCenter' // 认证中心首页
const GBT_ACCOUNT_INFO = baseUrl + '/mybusiness/enterpriseInformation' // 账号信息

export {
    GBT_LOGIN_URL,
    GBT_SET_ACCOUNT_URL,
    GBT_FORGET_PASSWORD_URL,
    GBT_EDIT_PASSWORD_URL,
    GBT_ENTERPRISE_AUTH_OR_INFO_URL,
    GBT_QUALIFICAT_AUTH_URL,
    GBT_CERTIFICATION_CENTER,
    GBT_ACCOUNT_INFO
}
